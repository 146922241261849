import React, { useEffect } from 'react'
import './preloader.css'
import { preLoaderAnim } from '../animation';
import loadergif from '../components/Image/Resolute-Loader.gif';

const PreLoader = () => {
    useEffect(()=>{
         preLoaderAnim();
    },[]);
  return (
    <div className='preloader'>
        <div className="gif-container">
           <img src={loadergif} alt='Loading....'/>
        </div>
    </div>
  )
}

export default PreLoader;