import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import dc from '../assets/Team/Dilip.jpg';
import amrita from '../assets/Team/Amrita.jpg';
import vivek from '../assets/Team/Vivek.jpg';
import manish from '../assets/Team/Manish.jpg';
import sunny from '../assets/Team/Sunny.jpg';
import shyam from '../assets/Team/Shyam.jpg';
import saiprakash from '../assets/Team/SaiPrakash.jpg';
import AnimatedTitle from './Animation/AnimatedTitle';

const TeamMeet = () => {
    const data = [
        {
            name: `Dilip Chakraborty`,
            desig: 'Group Accounts and Taxation',
            img: dc
        },
        {
            name: `Manish Kumar`,
            desig: 'Group General Counsel',
            img: manish
        },
        {
            name: `Amrita Desai`,
            desig: 'Group CHRO',
            img: amrita
        },
        {
            name: `Vivek Pawar`,
            desig: 'CEO HairDramaCo.',
            img: vivek
        },
        {
            name: `Sunny Savani`,
            desig: 'Co-Founder ShareSquare',
            img: sunny
        },
        {
            name: `Shyam Savani`,
            desig: 'Co-Founder ShareSquare',
            img: shyam
        },
        {
            name: `Sai Prakash`,
            desig: 'CEO Resolute Sports',
            img: saiprakash
        }
    ];

    return (
        <section className="w-full">
            <div className="max-w-[1300px] m-auto py-16 px-4">
                <h1 className="text-4xl uppercase font-bold text-center">
                    <AnimatedTitle>MEET THE TEAM</AnimatedTitle>
                </h1>
                <div className="mt-20 relative">
                    <style>
                        {`
                            .swiper-button-prev,
                            .swiper-button-next {
                                color: #0a235c; 
                                padding-left: 1px; 
                                padding-right: 1px; 
                            }

                            .swiper-pagination-bullet {
                                background: #0a235c; 
                            }

                            .swiper-pagination-bullet-active {
                                background: #0a235c; 
                            }
                        `}
                    </style>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={30}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        breakpoints={{
                            480: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        style={{
                            paddingBottom: '50px',
                        }}
                    >
                        {data.map((d, index) => (
                            <SwiperSlide key={index}>
                                <div className="bg-[#F4F5F6] text-black rounded-lg hover:bg-[#0a235c] hover:p-3 hover:text-white">
                                    <div className="rounded-t-xl flex justify-center items-center">
                                        <img src={d.img} alt={d.name} className="md:h-45 md:w-50 w-full h-full rounded" />
                                    </div>
                                    <div className="flex flex-col gap-4 p-5 justify-center items-center">
                                        <h5 className="text-2xl font-semibold">{d.name}</h5>
                                        <span className="font-bold">{d.desig}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default TeamMeet;
