import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import image1 from '../components/Image/Banner-1.jpg';
import image2 from '../components/Image/Banner-2.jpg';
import image3 from '../components/Image/Banner-3.jpg';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const SlideShow = () => {
  return (
    <Swiper
      modules={[Pagination, Autoplay, Navigation]}
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        enabled: true,
        delay: 4000,
        disableOnInteraction: false,
      }}
      loop={true}
      speed={6000}
      pagination={{ clickable: true }}
      navigation={true}
    >
      <SwiperSlide>
        <div className="relative h-full w-full">
          <img
            src={image1}
            alt="Slide 1"
            className="w-full md:h-[600px] h-[300px] object-cover image-fade"
          />
          <div className="absolute top-2/3 md:left-[9%] bottom-15 -translate-y-1/2 p-6 text-white ">
            <h2 className="md:text-5xl text-xl mb-2 uppercase">
              
              Fueling Innovation,<br />Empowering Tomorrow
              
            </h2>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-full w-full">
          <img
            src={image2}
            alt="Slide 2"
            className="w-full md:h-[600px] h-[300px] object-cover image-fade"
          />
          <div className="absolute md:left-[9%] bottom-15 top-2/3 -translate-y-1/2 p-6 text-white">
            <h2 className="md:text-5xl mb-2 text-xl uppercase">
           
              Progress<br />is vitality
             
            </h2>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-full w-full">
          <img
            src={image3}
            alt="Slide 3"
            className="w-full md:h-[600px] h-[300px] object-cover image-fade"
          />
          <div className="absolute md:left-[9%] bottom-15 top-2/3 -translate-y-1/2 p-6 text-white ">
            <h2 className="md:text-5xl mb-2 text-xl uppercase">
              
              Powering Possibilities,<br />Shaping Futures
              
            </h2>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default SlideShow;
